<template>
  <div style="margin: 20px;">
    <h3>Inventory<a type="text" style="font-size: 14px; color: green;" @click="add()"> New <i class="fas fa-plus-square"/></a></h3>
    <el-card shadow="never">
      <el-row>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form :inline="true" label-position="right">
            <el-form-item>
              <el-date-picker v-model="year_s" type="year" size="medium" placeholder="Year" clearable style="width: 100px;" />
            </el-form-item>
            <el-form-item>
              <el-select v-model="active_s" size="medium" placeholder="Select" style="width: 130px;">
                <el-option v-for="label in select_active" :key="label.value" :label="label.label" :value="label.value" />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input v-model="name_s" size="medium" placeholder="Personnel" style="width: 200px;" clearable />
            </el-form-item>
            <el-form-item>
              <el-tooltip content="Search" placement="top">
                <el-button type="primary" size="medium" icon="el-icon-search" @click="search()" />
              </el-tooltip>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" align="right">
          <el-button-group>
            <el-tooltip content="Print" placement="top">
              <el-button type="info" size="medium" @click="generateInventory(year_p, active_p)"><i class="fas fa-sync fa-print" /></el-button>
            </el-tooltip>
            <el-tooltip content="Excel" placement="top">
              <el-button type="info" size="medium" @click="generateExcel()"><i class="fas fa-file-excel" /></el-button>
            </el-tooltip>
            <el-tooltip content="Refresh" placement="top">
              <el-button type="info" size="medium" @click="refresh()"><i class="fas fa-sync fa-sync-alt" /></el-button>
            </el-tooltip>
          </el-button-group>
        </el-col>
      </el-row>
      <el-row>
        <el-table v-loading="loading" :data="inventory" size="mini" style="width: 100%; font-size: 14px;" height="545" highlight-current-row @current-change="handleCurrentChange">
          <el-table-column type="expand" label="..." width="48">
            <template slot-scope="scope">
              <el-row>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <p v-if="scope.row.personnel_id" class="para"><strong>Personnel: </strong>{{ scope.row.personnel.fullname }}</p>
                  <p v-if="scope.row.inactive_personnel" class="para"><strong>Personnel: </strong>{{ scope.row.inactive_personnel }}</p>
                  <p v-if="scope.row.personnel_id" class="para"><strong>Age: </strong>{{ getAge(scope.row.personnel.birthdate) }}</p>
                  <p v-if="scope.row.inactive_personnel_birthdate" class="para"><strong>Age: </strong>{{ getAge(scope.row.inactive_personnel_birthdate) }}</p>
                  <p v-if="scope.row.personnel_id" class="para">
                    <strong>Birthdate: </strong>
                    <span :title="getFormattedDate(scope.row.personnel.birthdate,'MMMM DD, Y')">{{ scope.row.personnel.birthdate }}</span>
                  </p>
                  <p v-if="scope.row.inactive_personnel_birthdate" class="para">
                    <strong>Birthdate: </strong>
                    <span :title="getFormattedDate(scope.row.inactive_personnel_birthdate,'MMMM DD, Y')">{{ scope.row.inactive_personnel_birthdate }}</span>
                  </p>
                  <p class="para">
                    <strong>Date separated: </strong>
                    <span :title="getFormattedDate(scope.row.date_sep,'MMMM DD, Y')">{{ scope.row.date_sep }}</span>
                  </p>
                  <p class="para"><strong>Year to be disposed: </strong>{{ scope.row.year_dis }}</p>
                  <p class="para"><strong>Retention period: </strong>15 years</p>
                  <p class="para"><strong>Status: </strong>{{ scope.row.status }}</p>
                  <p v-if="scope.row.remarks" class="para"><strong>Remarks: </strong>{{ scope.row.remarks }}</p>
                  <p class="para">
                    <strong>Employee status: </strong>
                    <span v-if="scope.row.active === 1">Current</span>
                    <span v-else>Non-Current</span>
                  </p>
                  <p class="para">
                    <strong>Records status: </strong>
                    <span v-if="scope.row.year_dis > new Date().getFullYear()" style="color: #4CAF50;">Retained</span>
                    <span v-else style="color: #f56c6c;">Disposed</span>
                  </p>
                  <p v-if="scope.row.add_by_personnel" class="para">
                    <strong>Added by: </strong>
                    <span :title="getFormattedDate(scope.row.created_at, 'MMMM Do YYYY, hh:mm:ss a')">{{ scope.row.add_by_personnel.name }}</span>
                  </p>
                  <p v-if="scope.row.update_by_personnel" class="para">
                    <strong>Updated by: </strong>
                    <span :title="getFormattedDate(scope.row.updated_at, 'MMMM Do YYYY, hh:mm:ss a')">{{ scope.row.update_by_personnel.name }}</span>
                  </p>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column label="Personnel" width="383">
            <template slot-scope="scope">
              <span v-if="scope.row.personnel_id">{{ scope.row.personnel.fullname }}</span>
              <span v-else>{{ scope.row.inactive_personnel }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Age" width="80">
            <template slot-scope="scope">
              <span v-if="scope.row.personnel_id">{{ getAge(scope.row.personnel.birthdate) }}</span>
              <span v-if="scope.row.inactive_personnel_birthdate">{{ getAge(scope.row.inactive_personnel_birthdate) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Date separated" width="150">
            <template slot-scope="scope">
              <span :title="getFormattedDate(scope.row.date_sep,'MMMM DD, Y')">
                {{ scope.row.date_sep }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="year_dis" label="Year to be disposed" width="200" />
          <el-table-column prop="status" label="Status" width="150" />
          <el-table-column label="Remarks" width="200">
            <template v-if="scope.row.remarks" slot-scope="scope">
              <span v-if="scope.row.remarks.length <= 20" style="word-break: break-word"> {{ scope.row.remarks }} </span>
              <span v-else style="word-break: break-word"> {{ scope.row.remarks.substring(0, 20) + " ..." }} </span>
            </template>
          </el-table-column>
          <el-table-column label="Employee status" width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.active === 1">Current</span>
              <span v-else>Non-Current</span>
            </template>
          </el-table-column>
          <el-table-column label="Records status" width="150" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.year_dis > new Date().getFullYear()">
                <el-tag type="success" effect="plain" size="medium" style="color: #4CAF50 !important; border-color: #4CAF50 !important;">Retained</el-tag>
              </span>
              <span v-else>
                <el-tag type="danger" effect="plain" size="medium">Disposed</el-tag>
              </span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="Operation" width="90">
            <template slot-scope="scope">
              <el-button-group>
                <el-tooltip content="Edit" placement="top">
                  <el-button type="primary" icon="el-icon-edit" size="mini" @click="edit(scope.$index)" />
                </el-tooltip>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-card>
    <div align="center">
      <el-pagination :page-size="meta.per_page" :pager-count="5" :total="meta.total" :current-page.sync="meta.page" layout="total, prev, pager, next" style="padding-top: 5px;" @current-change="getAll()"/>
    </div>
    <el-dialog v-if="selected" :visible.sync="editable" :title="title_inventory" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" top="5vh" width="50%">
      <el-row style="margin: -50px 0;">
        <el-divider />
        <p><strong>Note:</strong> Fields marked with asterisk (<span style="color: #f56c6c; font-weight: bold;">*</span>) are required to be filled.</p>
        <el-form ref="selected" :model="selected" :rules="rules">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="Personnel">
                <select-personnel v-if="editable" :post="selected.personnel" @setPersonnel="selected.personnel_id = $event" />
              </el-form-item>
              <el-collapse>
                <el-collapse-item title="Not found personnel ?" name="1">
                  <el-form-item label="Old Personnel">
                    <el-input v-if="editable" v-model="selected.inactive_personnel" type="text" maxlength="70" show-word-limit size="medium" placeholder="Input old personnel" clearable />
                  </el-form-item>
                  <el-form-item label="Birthdate">
                    <el-date-picker v-if="editable" v-model="selected.inactive_personnel_birthdate" type="date" size="medium" placeholder="Pick a date" style="width: 100%;" clearable />
                  </el-form-item>
                </el-collapse-item>
              </el-collapse>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="Date separated" prop="date_sep">
                <el-date-picker v-if="editable" v-model="selected.date_sep" type="date" size="medium" placeholder="Please pick a date" style="width: 100%;" clearable />
              </el-form-item>
              <el-form-item label="Year to disposed">
                <el-input v-if="editable" :value="dispose_year" size="medium" disabled />
              </el-form-item>
              <el-form-item label="Status" prop="status">
                <el-select v-if="editable" v-model="selected.status" size="medium" placeholder="Please select status">
                  <el-option v-for="select in select_status" :key="select.label" :value="select.label" :label="select.label" />
                </el-select>
              </el-form-item>
              <el-form-item label="Remarks">
                <el-input v-if="editable" v-model="selected.remarks" type="text" maxlength="30" show-word-limit size="medium" placeholder="Please input remarks" clearable />
              </el-form-item>
              <el-form-item label="Employee status">
                <br>
                <el-switch v-if="editable" v-model="selected.active" :active-value="1" :inactive-value="0" active-text="Current" inactive-text="Non-Current" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider />
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="medium" @click="submitForm('selected')">Save</el-button>
        <el-button type="danger" size="medium" @click="submitClose('selected')">Cancel</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { RecordsInventory } from '@/api/records/records_inventory'
import SelectPersonnel from '@/views/layout/components/SelectPersonnel'
import { Notification } from 'element-ui'

export default {
  name: 'RecordsInventory',
  components: { SelectPersonnel },
  data() {
    return {
      loading: false,
      editable: false,
      meta: {
        page: 1
      },
      year_s: null,
      active_s: 1,
      name_s: null,
      year_p: null,
      active_p: null,
      inventory: [],
      title_inventory: null,
      inactive: false,
      select_active: [
        { value: 1, label: 'Current' },
        { value: 0, label: 'Non-Current' }
      ],
      select_status: [
        { label: 'Resignation' },
        { label: 'Retirement' },
        { label: 'Transfer' },
        { label: 'Others' }
      ],
      selected: {
        personnel_id: null,
        inactive_personnel: null,
        inactive_personnel_birthdate: null,
        date_sep: null,
        year_dis: null,
        status: null,
        remarks: null,
        active: null
      },
      rules: {
        date_sep: [
          { required: true, message: 'Date separated is required' }
        ],
        status: [
          { required: true, message: 'Status is required' }
        ]
      },
      base_url: this.$store.getters.base_url,
      token: this.$store.getters.token
    }
  },
  computed: {
    dispose_year: function() {
      const birthSep = new Date(this.selected.date_sep)
      const yearSep = birthSep.getFullYear() + 15
      const thisYear = new Date().getFullYear()
      if (this.selected.date_sep) {
        if (yearSep > thisYear) return yearSep + ' - Retained'
        else return yearSep + ' - Disposed'
      } else {
        return ' '
      }
    }
  },
  // watch: {
  //   year_s: function() {
  //     this.meta.page = 1
  //     this.getAll()
  //   },
  //   active_s: function() {
  //     this.meta.page = 1
  //     this.getAll()
  //   },
  //   name_s: function() {
  //     this.meta.page = 1
  //     this.getAll()
  //   }
  // },
  created: function() {
    this.getAll()
  },
  methods: {
    getAll() {
      this.loading = true
      this.selected = null
      if (this.year_s !== null) {
        this.year_s = this.getFormattedYear(this.year_s)
      }
      RecordsInventory({
        page: this.meta.page,
        year: this.year_s,
        active: this.active_s,
        name: this.name_s
      }, 'get').then(res => {
        this.inventory = res.data.inventory
        this.meta = res.data.meta
        this.loading = false
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
      this.year_p = this.year_s
      this.active_p = this.active_s
    },
    search() {
      this.meta.page = 1
      this.getAll()
    },
    refresh() {
      this.meta.page = 1
      this.year_s = null
      this.active_s = 1
      this.name_s = null
      this.getAll()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save()
        } else {
          Notification.error({
            title: 'Error',
            message: 'Unable to save',
            duration: 5 * 1000
          })
          return false
        }
      })
    },
    submitClose(formName) {
      this.$refs[formName].resetFields()
      this.editable = false
    },
    add() {
      this.selected = {}
      this.title_inventory = 'New Inventory'
      this.editable = true
    },
    edit(index) {
      this.selected = Object.assign({}, this.inventory[index])
      this.title_inventory = 'Edit Inventory'
      this.editable = true
    },
    save() {
      if (this.selected.inactive_personnel_birthdate) {
        this.selected.inactive_personnel_birthdate = this.getFormattedDate(this.selected.inactive_personnel_birthdate)
      }
      this.selected.date_sep = this.getFormattedDate(this.selected.date_sep)
      this.selected.year_dis = this.dispose_year
      const action = this.selected.id ? 'update' : 'create'
      RecordsInventory(this.selected, action).then(res => {
        if (res.data.error === true) {
          Notification.error({
            title: 'Error',
            message: 'Data already existed',
            duration: 5 * 1000
          })
        } else {
          Notification.success({
            title: 'Success',
            message: 'Inventory has been saved successfully',
            duration: 5 * 1000
          })
          this.submitClose('selected')
          this.getAll()
        }
      }).catch(error => {
        console.log(error)
      })
    },
    getAge(DOB) {
      const today = new Date()
      const birthDate = new Date(DOB)
      let age = today.getFullYear() - birthDate.getFullYear()
      const m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1
      }
      return age
    },
    generateInventory(year, active) {
      if (year != null) {
        window.open(this.base_url + '/records/records_inventory/' + year + '/' + active + '/' + '?token=' + this.token, '_blank')
      } else {
        window.open(this.base_url + '/records/records_list_inventory/' + active + '/' + '?token=' + this.token, '_blank')
      }
    },
    generateExcel() {
      window.open(this.base_url + '/records/inventoryCSV/?token=' + this.token, '_blank')
    },
    // getStatus() {
    //   RecordsInventoryStatus({
    //     per_page: 9999
    //   }).then(res => {
    //     this.inventory_status = res.data.inventory_status
    //   }).catch(error => {
    //     console.log(error)
    //   })
    // },
    // getFormattedYear15(DOB) {
    //   const birthDate = new Date(DOB)
    //   return birthDate.getDate() + 15
    // },
    // getStatusFormatted(DOB) {
    //   const today = new Date()
    //   const birthDate = new Date(DOB)
    //   const me = birthDate.getFullYear() + 15
    //   if (me === today.getFullYear()) {
    //     return 'Disposed'
    //   } else {
    //     return 'Retained'
    //   }
    // },
    getFormattedTimeStamps() {
      return this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    },
    getFormattedDate(date, format = 'YYYY-MM-DD') {
      return this.$moment(date).format(format)
    },
    getFormattedYear(year) {
      return this.$moment(new Date(year)).format('YYYY')
    },
    handleCurrentChange(val) {
      this.currentRow = val
    }
  }
}
</script>

